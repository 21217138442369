import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Contact = () => {
  useEffect(() => {
    document.title = 'Contact';
  }, []);

  const navigate = useNavigate();

  const handleSubmit = () =>{
  fetch("https://formsubmit.co/ajax/d.standerwick@hotmail.com", {
    method: "POST",
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    body: JSON.stringify({
        name: document.getElementById("user_name").value,
        email: document.getElementById("user_email").value,
        message: document.getElementById("message").value
    })
})
    .then(response => response.json())
    .then((data) => console.log(data))
    .catch(error => console.log(error));
    navigate('/thanks');
}

    // navigate('/thanks');

  return (
    <main className="">
      <section className="container mx-auto">
          <h1 className="text-secondary text-5xl flex justify-center Roboto">Contact Form</h1>
          <h2 className="text-lg text-secondary flex justify-center mb-12">Fill out the form to send me an Email</h2>
          <form onSubmit={handleSubmit} className='bg-secondary grid gap-6 md:mx-auto w-full md:w-1/2 p-4 md:rounded-lg shadow-xl text-primary'>
            <label>Name</label>
            <input type="text" name="user_name" id="user_name" className='bg-primary border border-secondary text-secondary text-sm rounded-lg focus:ring-secondary-dark focus:border-secondary-dark block w-full p-2.5' />
            <label>Email</label>
            <input type="email" name="user_email" id="user_email" className='bg-primary border border-secondary text-secondary text-sm rounded-lg focus:ring-secondary-dark focus:border-secondary-dark block w-full p-2.5' />
            <label>Message</label>
            <textarea name="message" id="message" className='bg-primary border border-secondary text-secondary text-sm rounded-lg focus:ring-secondary-dark focus:border-secondary-dark block w-full p-2.5' />
            <input type="hidden" name="_captcha" value="false"></input>
            <button type="submit" className='btn border-2 border-primary rounded-xl mx-auto w-2/12 h-10 hover:bg-primary hover:text-secondary'>Send</button>
          </form>
      </section>
    </main>
  );
};